
import Main from './layouts/main';

export const App = () => {
  return (
    <div>
      <Main/>
    </div>
  );
};


export default App;
